// 英文
const en = {
  menu:{
    serviceScope:"Service Scope",// 服务范围
    regulatoryRegistration:"Regulatory Affairs",// 法规注册
    medicalAffairs:"Medical Affairs",// 医学事务
    clinicalOperationsProjectManagement:"Clinical Operation",
    dataManagementStatisticalAnalysis:"Biometrics",
    medicalSurveillance:"Pharmacovigilance",

    solution:"Solutions",
    frontierService:"Cutting-Edge Fields",
    clinicalDevelopmentStrategy:"Clinical Development Strategy",
    projectEvaluationPipelineSorting:"Project Evaluation and Pipeline Optimization",
    fsp:"Functional service provision (FSP)",

    about:"About",
    aboutNhx:"About Novostream",
    coreTeamMembers:"Core Team Members",
    companyNews:"Company News",

    careerDevelopment:"Careers",
    campusRecruitment:"Student & Campus Roles",
    socialRecruitment:"Experienced Roles",
    corporateCulture:"Corporate Culture",
    
    home:"Home",
    tooltipDesc:"more",
    contactUs:"Contact Us",
    releaseTime:"Release time",
    lang:"Lang",
  },
  // 首屏 - 关于
  homeAbout:{
    topic:"Competitive Strategy，Dependable Delivery，Genuine Services",
    topicDesc:"",
    company:"Copyright©  Pharmaceutical Technology Co., Ltd."
  },
  // 首屏 - 2
  homeUs:{
    aboutNHX:"About Us",
    nhxDesc:' Pharmaceutical Technology Co., Ltd. (hereinafter referred to as "Novostream") is a Contract Research Organization (CRO) established in July 2023, offering high-quality clinical research services to biopharmaceutical and medical device companies worldwide.Our professional and efficient team can timely address your needs, offering premium clinical research solutions at more competitive costs.',
  },
  // 子菜单
  menuPage:{
    companyDesc:' Pharmaceutical Technology Co., Ltd. (hereinafter referred to as "Novostream") is a Contract Research Organization (CRO) established in July 2023, offering high-quality clinical research services to biopharmaceutical and medical device companies worldwide.',
    quickLinks:"Quick Links",

    smallMoleculeTargetedAntiTumorDrugs:"Small-Molecule Targeted Anti-Tumor Drugs",
    cellAndGeneTherapy:"Cell and Gene Therapy",
    nuclearMedicine:"Nuclear Medicine",

    vacantPositionWaitingForYouToCome:"Vacant position waiting for you to come",
    
    underMaintenance:"Please waiting...",

    tel:"Tel",
    eMail:"E-mail",
    postalCode:"Postal code",

    prePage:"Previous page",
    nxtPage:"Next page",
  }
}

export default en;