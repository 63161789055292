import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    terminalType: 'l',
  },
  mutations: {
    SET_TERMINALTYPE (state, value) {
      state.terminalType = value
    }
  },
  actions: {
    setTerminalType({commit},info){
      commit('SET_TERMINALTYPE',info)
    }
  },
  modules: {}
})