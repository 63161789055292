<template>
  <div id="app" ref="pageMain">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import HelloWorld from './components/HelloWorld.vue'
// import ScreenView from "./business/screenView"
// import screenUI from "./business/screenUI"
export default {
  name: "App",
  computed: {},
  components: {},
  watch:{},
  mounted() {
    let _this = this;
    _this.setTerminalTypeFn();
    window.addEventListener("resize", function () {
      _this.$EventBus.$emit("windowSizeChange");
      _this.setTerminalTypeFn();
    });
  },
  methods: {
    ...mapActions(["setTerminalType"]),
    setTerminalTypeFn() {
      let _this = this;
      // _this.setTerminalType("l");
      let pageWidth = _this.$refs.pageMain.getBoundingClientRect().width;
      if (pageWidth < window.MOBILEBORDER) {
        _this.setTerminalType("s");
      } else {
        _this.setTerminalType("l");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@media only screen and (min-width: 320px) {
  html {
    font-size: 62.5% !important;
  }
}
@media only screen and (min-width: 640px) {
  html {
    font-size: 125% !important;
  }
}
@media only screen and (min-width: 750px) {
  html {
    font-size: 150% !important;
  }
}
@media only screen and (min-width: 1242px) {
  html {
    font-size: 187.5% !important;
  }
}
</style>
