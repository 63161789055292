import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import echarts from 'echarts'
import "./assets/css/style.scss"
import 'animate.css';
import i18n from "./i18n";
import Router from 'vue-router'
import store from './store'  //重要
import Vant from 'vant'
import 'vant/lib/index.css';
//导入axios模块
import axios from 'axios'
//把axios挂载到vue原型上
Vue.prototype.$http = axios;
Vue.prototype.$EventBus = new Vue()
Vue.use(Router)
import router from './router'

import './utils/rem'

// 将dataV注册为全局变量
Vue.use(dataV)
// 将Element Ui注册为全局变量
Vue.use(ElementUI)
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
