import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 引入各个语言配置文件
import zh from './config/zh';
import en from './config/en';
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
    // 设置默认语言
    locale:localStorage.getItem('locale') || 'zh', //语言标识
    // 添加多语言(每一个语言标示对应一个语言文件)
    messages:{
        zh, //中文 
        en, //英文
    },
    silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
    globalInjection: true, // 全局注入
    fallbackLocale: 'zh', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文
})

export default i18n;