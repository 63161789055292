import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "*",
      redirect: {
        name: 'home'
      }
    },
    {
      name: "home",
      path: '/home',
      component: () => import('../business/home'),
      meta: {
        keepAlive: true,
      },
      hidden: true
    },
    // 关于赛赋
    {
      // 关于我们
      name: "about",
      path: '/about',
      component: () => import('../business/about'),
      meta: {
        keepAlive: false,
      },
      hidden: true,
      children: [
        {
          // 公司简介
          name: "companyIntroduction",
          path: '/about/companyIntroduction',
          component: () => import('../business/about/companyIntroduction'),
        },
        {
          // 企业文化
          name: "corporateCulture",
          path: '/about/corporateCulture',
          component: () => import('../business/about/corporateCulture'),
        },
        {
          // 发展历程
          name: "developmentHistory",
          path: '/about/developmentHistory',
          component: () => import('../business/about/developmentHistory'),
        },
        {
          // 荣誉资质
          name: "honoraryQualifications",
          path: '/about/honoraryQualifications',
          component: () => import('../business/about/honoraryQualifications'),
        },
      ],
      redirect: {
        name: 'companyIntroduction'
      }
    },
    // 赛赋服务
    {
      name: "safeService",
      path: '/safeService',
      component: () => import('../business/safeService'),
      meta: {
        keepAlive: false,
      },
      hidden: true,
      children: [
        {
          // 抗体药物发现
          name: "ktywfx",
          path: '/safeService/ktywfx',
          component: () => import('../business/safeService/ktywfx'),
        },
        {
          // 筛选及成药性评价
          name: "sxjcyxpj",
          path: '/safeService/sxjcyxpj',
          component: () => import('../business/safeService/sxjcyxpj'),
        },
        {
          // 药理药效学评价
          name: "ylyxxpj",
          path: '/safeService/ylyxxpj',
          component: () => import('../business/safeService/ylyxxpj'),
        },
        {
          // 药物代谢动力评价
          name: "ywdxdlpj",
          path: '/safeService/ywdxdlpj',
          component: () => import('../business/safeService/ywdxdlpj'),
        },
        {
          // 非临床安全性评价
          name: "flcaqxpj",
          path: '/safeService/flcaqxpj',
          component: () => import('../business/safeService/flcaqxpj'),
        },
        {
          // 临床检测
          name: "lcjc",
          path: '/safeService/lcjc',
          component: () => import('../business/safeService/lcjc'),
        },
        {
          // 注册咨询
          name: "zczx",
          path: '/safeService/zczx',
          component: () => import('../business/safeService/zczx'),
        },
        {
          // 总包服务
          name: "zbfw",
          path: '/safeService/zbfw',
          component: () => import('../business/safeService/zbfw'),
        },
      ],
      redirect: {
        name: 'ktywfx'
      }
    },
    // 产品中心
    {
      name: "productCenter",
      path: '/productCenter',
      component: () => import('../business/productCenter'),
      meta: {
        keepAlive: false,
      },
      hidden: true,
      children: [
        {
          // 蛋白抗体
          name: "cpzx",
          path: '/productCenter/cpzx',
          component: () => import('../business/productCenter/cpzx'),
        },
        {
          // 蛋白抗体
          name: "dbkt",
          path: '/productCenter/dbkt',
          component: () => import('../business/productCenter/dbkt'),
        },
        {
          // 细胞产品
          name: "xbcp",
          path: '/productCenter/xbcp',
          component: () => import('../business/productCenter/xbcp'),
        },
        {
          // 血清
          name: "xq",
          path: '/productCenter/xq',
          component: () => import('../business/productCenter/xq'),
        },
        {
          // 其他产品
          name: "otherProducts",
          path: '/productCenter/otherProducts',
          component: () => import('../business/productCenter/otherProducts'),
        },
      ],
      redirect: {
        name: 'cpzx'
      }
    },
    // 赛赋特色
    {
      name: "safeFeatures",
      path: '/safeFeatures',
      component: () => import('../business/safeFeatures'),
      meta: {
        keepAlive: false,
      },
      hidden: true,
      children: [
        {
          // 赛赋特色
          name: "sfts",
          path: '/safeFeatures/sfts',
          component: () => import('../business/safeFeatures/sfts'),
        },
        {
          // 吸入药物一站式服务平台
          name: "xrywyzsfwpt",
          path: '/safeFeatures/xrywyzsfwpt',
          component: () => import('../business/safeFeatures/xrywyzsfwpt'),
        },
        {
          // 精神神经药物一站式服务平台
          name: "jssjywyzsfwpt",
          path: '/safeFeatures/jssjywyzsfwpt',
          component: () => import('../business/safeFeatures/jssjywyzsfwpt'),
        },
        {
          // 眼科药物一站式服务平台
          name: "ykywyzsfwpt",
          path: '/safeFeatures/ykywyzsfwpt',
          component: () => import('../business/safeFeatures/ykywyzsfwpt'),
        },
        {
          // ADC
          name: "adc",
          path: '/safeFeatures/adc',
          component: () => import('../business/safeFeatures/adc'),
        },
      ],
      redirect: {
        name: 'sfts'
      }
    },
    // 新闻资讯
    {
      name: "newsCenter",
      path: '/newsCenter',
      component: () => import('../business/newsCenter'),
      meta: {
        keepAlive: false,
      },
      hidden: true,
      children: [
        {
          // 公示公告
          name: "gsgg",
          path: '/newsCenter/gsgg',
          component: () => import('../business/newsCenter/gsgg'),
        },
        {
          // 市场活动
          name: "schd",
          path: '/newsCenter/schd',
          component: () => import('../business/newsCenter/schd'),
        },
      ],
      redirect: {
        name: 'gsgg'
      }
    },
    // 联系我们
    {
      name: "contactUs",
      path: '/contactUs',
      component: () => import('../business/contactUs'),
      meta: {
        keepAlive: false,
      },
      hidden: true,
      children: [
        {
          // 商务合作
          name: "swhz",
          path: '/contactUs/swhz',
          component: () => import('../business/contactUs/swhz'),
        },
        {
          // 加入我们
          name: "jrwm",
          path: '/contactUs/jrwm',
          component: () => import('../business/contactUs/jrwm'),
        },
      ],
      redirect: {
        name: 'gsgg'
      }
    },
  ]
})
export default router
