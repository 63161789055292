// 中文
const zh = {
  menu:{
    serviceScope:"服务范围",
    regulatoryRegistration:"法规注册",
    medicalAffairs:"医学事务",
    clinicalOperationsProjectManagement:"临床运营与项目管理",
    dataManagementStatisticalAnalysis:"数据管理与统计分析",
    medicalSurveillance:"药物警戒",
    
    solution:"解决方案",
    frontierService:"前沿领域",
    clinicalDevelopmentStrategy:"临床开发策略",
    projectEvaluationPipelineSorting:"立项评估与管线梳理",
    fsp:"功能性外包（FSP）",

    about:"关于我们",
    aboutNhx:"关于诺和欣",
    coreTeamMembers:"核心团队成员",
    companyNews:"公司新闻",

    careerDevelopment:"职业发展",
    campusRecruitment:"校园招聘",
    socialRecruitment:"社会招聘",
    corporateCulture:"企业文化",
    
    home:"首页",
    tooltipDesc:"了解更多",
    contactUs:"联系我们",
    releaseTime:"发布时间",
    lang:"语言",
  },
  // 首屏 - 关于
  homeAbout:{
    topic:"优势策略，可靠交付，真诚服务",
    topicDesc:"Competitive Strategy，Dependable Delivery，Genuine Services",
    company:"北京赛赋医药研究院有限公司 版权所有   Pharmaceutical Tech. Co., Ltd."
  },
  // 首屏 - 2
  homeUs:{
    aboutNHX:"关于诺和欣",
    nhxDesc:"北京赛赋医药研究院有限公司（简称“诺和欣”）是一家成立于2023年7月的合同研究组织（Contract Research Organization，CRO），致力于为国内外创新生物制药企业和创新医疗器械企业提供高水平、高质量、全方位临床研究服务。我们专业且高效的团队能够快速响应您的需求，并以更具竞争力的成本，给您提供高标准的临床研究解决方案。",
  },
  // 子菜单
  menuPage:{
    companyDesc:"北京赛赋医药研究院有限公司成立于2023年7月，是一家致力于为国内外创新生物制药企业和创新医疗器械企业提供高水平、高质量、全方位临床研究服务的合同研究组织（Contract Research Organization，CRO）。",
    quickLinks:"快速链接",

    smallMoleculeTargetedAntiTumorDrugs:"小分子靶向抗肿瘤药物",
    cellAndGeneTherapy:"CGT细胞和基因治疗",
    nuclearMedicine:"放射性药物",

    vacantPositionWaitingForYouToCome:"虚位以待 职等你来",

    underMaintenance:"敬请期待...",

    tel:"电话（Tel）",
    eMail:"邮箱（E-mail）",
    postalCode:"邮编（Postal code）",

    prePage:"上一页",
    nxtPage:"下一页",
  }
}

export default zh;